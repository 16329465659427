import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type ClientInfo = {
  current_url?: string;
  client_device?: string;
  client_browser?: string;
};

const getBrowserName = (agent) => {
  let browserInfo = agent;
  let browser;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = "Opera";
  } else if (browserInfo.includes("Edg")) {
    browser = "Edge";
  } else if (browserInfo.includes("Chrome")) {
    browser = "Chrome";
  } else if (browserInfo.includes("Safari")) {
    browser = "Safari";
  } else if (browserInfo.includes("Firefox")) {
    browser = "Firefox";
  } else {
    browser = "unknown";
  }
  return browser;
};

const getDeviceType = (agent) => {
  const ua = agent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

function useClientInfo(): ClientInfo {
  const router = useRouter();
  const [clientInfo, setClientInfo] = useState<ClientInfo>({
    current_url: process.env.NEXT_PUBLIC_APP_URL + router?.asPath,
    client_device: "",
    client_browser: "",
  });
  useEffect(() => {
    if (typeof navigator !== undefined) {
      setClientInfo((prev) => ({
        ...prev,
        client_browser: getBrowserName(navigator.userAgent),
        client_device: getDeviceType(navigator.userAgent),
      }));
    }
  }, []);

  return clientInfo as ClientInfo;
}

export default useClientInfo;
