import { useCallback, useRef } from "react";
import Slider from "react-slick";

const useSliderNavigation = () => {
  const sliderRef = useRef<Slider | null>(null);

  const next = useCallback(() => {
    sliderRef.current.slickNext();
  }, [sliderRef]);
  const previous = useCallback(() => {
    sliderRef.current.slickPrev();
  }, [sliderRef]);

  return { sliderRef, next, previous };
};

export default useSliderNavigation;
