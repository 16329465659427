import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import clsx from "clsx";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: (props: PaginationAndNavigationProps) =>
          props.navigationAlignmentOnMobile
            ? props.navigationAlignmentOnMobile
            : "start",
      },
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    white: {
      color: theme.palette.common.white,
    },
    button: {
      "&:disabled": {
        opacity: "0.3",
        cursor: "default",
        pointerEvents: "none",
      },
    },
    hidden: {
      display: "none",
    },
    pagination: {
      bottom: 10,
      left: 0,
      width: "auto",
      display: "flex",

      "& .swiper-pagination-bullet": {
        display: "inline-block",
        width: 20,
        height: 7,
        borderRadius: 50,
        border: `1px solid ${theme.palette.primary.main}`,
        transition: "all .5s",
        cursor: "pointer",

        "&:not(:last-child)": {
          marginRight: 5,
        },
        [theme.breakpoints.down("xs")]: {
          justifyContent: "center",
          display: (props: PaginationAndNavigationProps) =>
            props.paginationHideOnMobile ? "none" : " block",
        },
      },
      "& .swiper-pagination-bullet-active": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  })
);
interface PaginationAndNavigationProps {
  nextClassName: string;
  prevClassName: string;
  paginationClassName: string;
  color?: "primary" | "secondary" | "white" | "error";
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  align?: "center" | "end" | "start";
  paginationHideOnMobile?: boolean;
  navigationAlignmentOnMobile?: "center" | "end" | "start";
}

const PaginationAndNavigation: React.FC<PaginationAndNavigationProps> = (
  props
) => {
  const {
    color,
    nextClassName,
    prevClassName,
    paginationClassName,
    hideNextButton = false,
    hidePrevButton = false,
    align = "start",
  } = props;
  const classes = useStyles(props);
  return (
    <Box
      className={classes.root}
      display="flex"
      alignItems="center"
      justifyContent={align}
    >
      <IconButton
        size="small"
        title="Previous"
        className={clsx(classes.button, {
          [prevClassName]: prevClassName !== undefined,
          [classes.hidden]: hidePrevButton,
        })}
      >
        <KeyboardBackspaceIcon
          fontSize="large"
          className={clsx(classes.primary, {
            [classes.primary]: color === "primary",
            [classes.secondary]: color === "secondary",
            [classes.error]: color === "error",
            [classes.white]: color === "white",
          })}
        />
      </IconButton>
      <div
        className={clsx(classes.pagination, {
          [paginationClassName]: paginationClassName !== undefined,
        })}
      ></div>
      <IconButton
        size="small"
        title="Next"
        className={clsx(classes.button, {
          [nextClassName]: nextClassName !== undefined,
        })}
        hidden={hideNextButton}
      >
        <KeyboardBackspaceIcon
          className={clsx(classes.primary, {
            [classes.primary]: color === "primary",
            [classes.secondary]: color === "secondary",
            [classes.error]: color === "error",
            [classes.white]: color === "white",
          })}
          style={{
            transform: "rotate(180deg)",
          }}
          fontSize="large"
        />
      </IconButton>
    </Box>
  );
};

export default PaginationAndNavigation;
