import React from "react";
import { hasHTMLTags } from "./helpers";

function renderHTML(htmlString) {
  if (hasHTMLTags(htmlString)) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }
  return htmlString;
}

export default renderHTML;
